import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import SeoLeftColumn from "../../components/SeoLeftColumn";
import SeoRightColumn from "../../components/SeoRightColumn";

// 1. define Hreflang data, for href tags and language button
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/seo"
  },
  {
    hreflang: "de",
    href: "/de/seo"
  },
  {
    hreflang: "da",
    href: "/da/seo"
  },
  {
    hreflang: "se",
    href: "/se/seo"
  },
  {
    hreflang: "no",
    href: "/no/seo"
  },
  {
    hreflang: "nl",
    href: "/nl/seo"
  },
  {
    hreflang: "x-default",
    href: "/en/seo"
  }
];


const Seo = ({ location }) => { 
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Tutorialer om SEO & SEO-verktyg"
          lang="se"
          description="Höj dina SEO-färdigheter med handledningar om SEO och DIY SEO-verktyg. Bli mästare på nyckelordsforskning, on-page optimering och mer med våra steg-för-steg-guider."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
        />
        <MainFrontpage>
          <SeoLeftColumn />
          <SeoRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Seo);
